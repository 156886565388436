.particlesBox {
	position: fixed;
	bottom: 150px;
	right: 400px;
	z-index: 20;
	width: 50px;
	height: 50px;
}

.musicIcon {
	position: absolute;
	bottom: 40px;
	right: 40px;
	width: 30px;
	height: 30px;
	object-fit: contain;
	filter: invert(100%);
	opacity: 0;
}

.icon1 {
	animation: musicMove 3s;
	animation-iteration-count: infinite;
	animation-delay: 1s;
}

.icon2 {
	animation: musicMove 5s;
	animation-iteration-count: infinite;
	animation-delay: 3s;
}

.icon3 {
	animation: musicMove 5s;
	animation-iteration-count: infinite;
	animation-delay: 2s;
}

@keyframes musicMove {
	0%	{ transform: translate(	0px,	0px); opacity:	0.2	;}
	1%	{ transform: translate(	2px,	-0.016px); opacity:	0.4	;}
	2%	{ transform: translate(	4px,	-0.064px); opacity:	0.6	;}
	3%	{ transform: translate(	6px,	-0.144px); opacity:	0.8	;}
	4%	{ transform: translate(	8px,	-0.256px); opacity:	1	;}
	5%	{ transform: translate(	10px,	-0.4px); opacity:	1	;}
	6%	{ transform: translate(	12px,	-0.576px); opacity:	1	;}
	7%	{ transform: translate(	14px,	-0.784px); opacity:	1	;}
	8%	{ transform: translate(	16px,	-1.024px); opacity:	1	;}
	9%	{ transform: translate(	18px,	-1.296px); opacity:	1	;}
	10%	{ transform: translate(	20px,	-1.6px); opacity:	1	;}
	11%	{ transform: translate(	22px,	-1.936px); opacity:	1	;}
	12%	{ transform: translate(	24px,	-2.304px); opacity:	1	;}
	13%	{ transform: translate(	26px,	-2.704px); opacity:	1	;}
	14%	{ transform: translate(	28px,	-3.136px); opacity:	1	;}
	15%	{ transform: translate(	30px,	-3.6px); opacity:	1	;}
	16%	{ transform: translate(	32px,	-4.096px); opacity:	1	;}
	17%	{ transform: translate(	34px,	-4.624px); opacity:	1	;}
	18%	{ transform: translate(	36px,	-5.184px); opacity:	1	;}
	19%	{ transform: translate(	38px,	-5.776px); opacity:	1	;}
	20%	{ transform: translate(	40px,	-6.4px); opacity:	1	;}
	21%	{ transform: translate(	42px,	-7.056px); opacity:	1	;}
	22%	{ transform: translate(	44px,	-7.744px); opacity:	1	;}
	23%	{ transform: translate(	46px,	-8.464px); opacity:	1	;}
	24%	{ transform: translate(	48px,	-9.216px); opacity:	1	;}
	25%	{ transform: translate(	50px,	-10px); opacity:	1	;}
	26%	{ transform: translate(	52px,	-10.816px); opacity:	1	;}
	27%	{ transform: translate(	54px,	-11.664px); opacity:	1	;}
	28%	{ transform: translate(	56px,	-12.544px); opacity:	1	;}
	29%	{ transform: translate(	58px,	-13.456px); opacity:	1	;}
	30%	{ transform: translate(	60px,	-14.4px); opacity:	1	;}
	31%	{ transform: translate(	62px,	-15.376px); opacity:	1	;}
	32%	{ transform: translate(	64px,	-16.384px); opacity:	1	;}
	33%	{ transform: translate(	66px,	-17.424px); opacity:	1	;}
	34%	{ transform: translate(	68px,	-18.496px); opacity:	1	;}
	35%	{ transform: translate(	70px,	-19.6px); opacity:	1	;}
	36%	{ transform: translate(	72px,	-20.736px); opacity:	1	;}
	37%	{ transform: translate(	74px,	-21.904px); opacity:	1	;}
	38%	{ transform: translate(	76px,	-23.104px); opacity:	1	;}
	39%	{ transform: translate(	78px,	-24.336px); opacity:	1	;}
	40%	{ transform: translate(	80px,	-25.6px); opacity:	1	;}
	41%	{ transform: translate(	82px,	-26.896px); opacity:	1	;}
	42%	{ transform: translate(	84px,	-28.224px); opacity:	1	;}
	43%	{ transform: translate(	86px,	-29.584px); opacity:	1	;}
	44%	{ transform: translate(	88px,	-30.976px); opacity:	1	;}
	45%	{ transform: translate(	90px,	-32.4px); opacity:	1	;}
	46%	{ transform: translate(	92px,	-33.856px); opacity:	1	;}
	47%	{ transform: translate(	94px,	-35.344px); opacity:	1	;}
	48%	{ transform: translate(	96px,	-36.864px); opacity:	1	;}
	49%	{ transform: translate(	98px,	-38.416px); opacity:	1	;}
	50%	{ transform: translate(	100px,	-40px); opacity:	1	;}
	51%	{ transform: translate(	102px,	-41.616px); opacity:	1	;}
	52%	{ transform: translate(	104px,	-43.264px); opacity:	1	;}
	53%	{ transform: translate(	106px,	-44.944px); opacity:	1	;}
	54%	{ transform: translate(	108px,	-46.656px); opacity:	1	;}
	55%	{ transform: translate(	110px,	-48.4px); opacity:	1	;}
	56%	{ transform: translate(	112px,	-50.176px); opacity:	1	;}
	57%	{ transform: translate(	114px,	-51.984px); opacity:	1	;}
	58%	{ transform: translate(	116px,	-53.824px); opacity:	1	;}
	59%	{ transform: translate(	118px,	-55.696px); opacity:	1	;}
	60%	{ transform: translate(	120px,	-57.6px); opacity:	0.975	;}
	61%	{ transform: translate(	122px,	-59.536px); opacity:	0.95	;}
	62%	{ transform: translate(	124px,	-61.504px); opacity:	0.925	;}
	63%	{ transform: translate(	126px,	-63.504px); opacity:	0.9	;}
	64%	{ transform: translate(	128px,	-65.536px); opacity:	0.875	;}
	65%	{ transform: translate(	130px,	-67.6px); opacity:	0.85	;}
	66%	{ transform: translate(	132px,	-69.696px); opacity:	0.825	;}
	67%	{ transform: translate(	134px,	-71.824px); opacity:	0.8	;}
	68%	{ transform: translate(	136px,	-73.984px); opacity:	0.775	;}
	69%	{ transform: translate(	138px,	-76.176px); opacity:	0.75	;}
	70%	{ transform: translate(	140px,	-78.4px); opacity:	0.725	;}
	71%	{ transform: translate(	142px,	-80.656px); opacity:	0.7	;}
	72%	{ transform: translate(	144px,	-82.944px); opacity:	0.675	;}
	73%	{ transform: translate(	146px,	-85.264px); opacity:	0.65	;}
	74%	{ transform: translate(	148px,	-87.616px); opacity:	0.625	;}
	75%	{ transform: translate(	150px,	-90px); opacity:	0.6	;}
	76%	{ transform: translate(	152px,	-92.416px); opacity:	0.575	;}
	77%	{ transform: translate(	154px,	-94.864px); opacity:	0.55	;}
	78%	{ transform: translate(	156px,	-97.344px); opacity:	0.525	;}
	79%	{ transform: translate(	158px,	-99.856px); opacity:	0.5	;}
	80%	{ transform: translate(	160px,	-102.4px); opacity:	0.475	;}
	81%	{ transform: translate(	161px,	-104.976px); opacity:	0.45	;}
	82%	{ transform: translate(	161.5px,	-107.584px); opacity:	0.425	;}
	83%	{ transform: translate(	162px,	-110.224px); opacity:	0.4	;}
	84%	{ transform: translate(	162.3px,	-112.896px); opacity:	0.375	;}
	85%	{ transform: translate(	163.6px,	-115.6px); opacity:	0.35	;}
	86%	{ transform: translate(	164px,	-118.336px); opacity:	0.325	;}
	87%	{ transform: translate(	163.6px,	-121.104px); opacity:	0.3	;}
	88%	{ transform: translate(	162.3px,	-123.904px); opacity:	0.275	;}
	89%	{ transform: translate(	162px,	-126.736px); opacity:	0.25	;}
	90%	{ transform: translate(	161.5px,	-129.6px); opacity:	0.225	;}
	91%	{ transform: translate(	161px,	-132.496px); opacity:	0.2	;}
	92%	{ transform: translate(	160px,	-135.424px); opacity:	0.175	;}
	93%	{ transform: translate(	159px,	-138.384px); opacity:	0.15	;}
	94%	{ transform: translate(	158px,	-141.376px); opacity:	0.125	;}
	95%	{ transform: translate(	157px,	-144.4px); opacity:	0.1	;}
	96%	{ transform: translate(	156px,	-147.456px); opacity:	0.075	;}
	97%	{ transform: translate(	155px,	-150.544px); opacity:	0.05	;}
	98%	{ transform: translate(	154px,	-153.664px); opacity:	0.025	;}
	99%	{ transform: translate(	153px,	-156.816px); opacity:	0	;}
	100%	{ transform: translate(	152px,	-160px); opacity:	0	;}
}